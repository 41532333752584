import React, { useEffect } from "react";
import { Container, Card } from "react-bootstrap";

function Acknowledgement(props) {
  const { requestID, assessmentID } = props;

  useEffect(() => {
    setTimeout(() => {
      window.location.replace(
        `${process.env.REACT_APP_CURRENT_ENVIRONMENT_TASKING}/${requestID}`
      );
    }, 2000);
  }, [requestID]);

  return (
    <div>
      <Container className="my-5">
        <Card>
          <Card.Header>
            <h3 className="text-center text-primary mb-0">Confirmation</h3>
          </Card.Header>
          <Card.Body className="ml-2 text-justify">
            <div className="my-4 fw-bold">
              <Card.Text>
                Do NOT close this browser window! Please wait to be re-directed
                to WireWheel to complete intake questions in Section A of the
                Privacy Impact Assessment (PIA).
              </Card.Text>
            </div>
            <Card.Text>
              Your <strong>Assessment ID: {assessmentID}</strong>. Please save
              this for your records.
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default Acknowledgement;
