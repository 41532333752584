import React from "react";
import { Navbar, Image } from "react-bootstrap";
import Logo from "../images/logo.png";
import { Container } from "react-bootstrap";

function Header() {
  return (
    <Navbar collapseOnSelect expand="lg" bg="white" variant="light">
      <Container>
        <Navbar.Brand href="https://www.microsoft.com/">
          <Image
            src={Logo}
            alt="Microsoft logo"
            className="p-2"
            width="160px"
          />
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
}

export default Header;
