import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Header from "./shared/components/Header";
import Footer from "./shared/components/Footer";
import "./App.scss";
import FPIR from "./components/FinancePrivacyIntakeRequest";

function App() {
  return (
    <>
      <Header />
      <Router>
        <Switch>
          <Route path="/" exact component={FPIR} />

          {/* catch all */}
          <Redirect path from="/*" to="/" component={FPIR} />
        </Switch>
      </Router>
      <Footer />
    </>
  );
}

export default App;
